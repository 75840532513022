import _ from 'lodash';
import $ from 'jquery';
import './App.scss';
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { images } from "./utils/imagesToLoad";
import { orderTypes } from './utils/orderTypes';
import { useEtcStore } from "./store/etcStore";
import Router from "./components/GlobalComponents/Router/AppRouter";
import Cart from './components/GlobalComponents/Cart/Cart';

function App() {
  const { setIsMobile, orderType, setOrderType } = useEtcStore();
  const [ isLoading, setIsLoading ] = useState(true);

  const handleScreenResize = (innerWidth) =>
    innerWidth <= 992 ? setIsMobile(true) : setIsMobile(false);

  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    handleScreenResize(window.innerWidth);
    window.addEventListener('resize', (e) => handleScreenResize(e.target.innerWidth));

    isLoading && $('body').addClass("ovf-hidden");
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => console.log("Failed to load images", err));
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {
    !isLoading && $('body').removeClass("ovf-hidden");
    !isLoading && $('#loader').addClass("hide");
  }, [isLoading]); 

  useEffect(() => {
    if (_.isEmpty(orderType)) {
      setOrderType(orderTypes[0]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter>
      <Cart />
      <Router />
    </BrowserRouter>
  );
}

export default App;
