import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import Button from '../../GlobalComponents/Button/Button';
import { useTranslation } from "react-i18next";
import smoke_01 from '../../../assets/img/HomePage/Banner/smoke_01.png';
import smoke_02 from '../../../assets/img/HomePage/Banner/smoke_02.png';
import fryer from '../../../assets/img/HomePage/Banner/fryer.png';
import item_01 from '../../../assets/img/HomePage/Banner/item_01.png';
import item_02 from '../../../assets/img/HomePage/Banner/item_02.png';
import item_03 from '../../../assets/img/HomePage/Banner/item_03.png';
import item_04 from '../../../assets/img/HomePage/Banner/item_04.png';
import item_05 from '../../../assets/img/HomePage/Banner/item_05.png';
import item_06 from '../../../assets/img/HomePage/Banner/item_06.png';
import item_07 from '../../../assets/img/HomePage/Banner/item_07.png';
import item_08 from '../../../assets/img/HomePage/Banner/item_08.png';
import item_09 from '../../../assets/img/HomePage/Banner/item_09.png';
import item_10 from '../../../assets/img/HomePage/Banner/item_10.png';
import item_11 from '../../../assets/img/HomePage/Banner/item_11.png';
import image_mob from '../../../assets/img/HomePage/Banner/image_mob.png';
import { gsap } from "gsap";
import CustomEase from "gsap/CustomEase";
gsap.registerPlugin(CustomEase);
gsap.config({
  force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const fryerRef = useRef(null);
    const itemRef_01 = useRef(null);
    const itemRef_02 = useRef(null);
    const itemRef_03 = useRef(null);
    const itemRef_04 = useRef(null);
    const itemRef_05 = useRef(null);
    const itemRef_06 = useRef(null);
    const itemRef_07 = useRef(null);
    const itemRef_08 = useRef(null);
    const itemRef_09 = useRef(null);
    const itemRef_10 = useRef(null);
    const itemRef_11 = useRef(null);
    const smokeRef_01 = useRef(null);
    const smokeRef_02 = useRef(null);
    
    const items = [
        {
            image: item_01,
            class: classes.item_01,
            ref: itemRef_01,
        },
        {
            image: item_02,
            class: classes.item_02,
            ref: itemRef_02,
        },
        {
            image: item_03,
            class: classes.item_03,
            ref: itemRef_03,
        },
        {
            image: item_04,
            class: classes.item_04,
            ref: itemRef_04,
        },
        {
            image: item_05,
            class: classes.item_05,
            ref: itemRef_05,
        },
        {
            image: item_06,
            class: classes.item_06,
            ref: itemRef_06,
        },
        {
            image: item_07,
            class: classes.item_07,
            ref: itemRef_07,
        },
        {
            image: item_08,
            class: classes.item_08,
            ref: itemRef_08,
        },
        {
            image: item_09,
            class: classes.item_09,
            ref: itemRef_09,
        },
        {
            image: item_10,
            class: classes.item_10,
            ref: itemRef_10,
        },
        {
            image: item_11,
            class: classes.item_11,
            ref: itemRef_11,
        },
    ];
    
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            const endParams = {
                rotate: 0,
                scale: 1,
                autoAlpha: 1,
                y: 0,
                x: 0,
                duration: 2,
                delay: 1,
            };
            gsap.to(fryerRef.current, endParams);
            gsap.to(smokeRef_01.current, endParams);
            gsap.to(smokeRef_02.current, endParams);
            gsap.to(itemRef_01.current, endParams);
            gsap.to(itemRef_02.current, endParams);
            gsap.to(itemRef_03.current, endParams);
            gsap.to(itemRef_04.current, endParams);
            gsap.to(itemRef_05.current, endParams);
            gsap.to(itemRef_06.current, endParams);
            gsap.to(itemRef_07.current, endParams);
            gsap.to(itemRef_08.current, endParams);
            gsap.to(itemRef_09.current, endParams);
            gsap.to(itemRef_10.current, endParams);
            gsap.to(itemRef_11.current, endParams);
        }
    }, []);

    return (
        <section className={classes.bannerWrap}>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h1 className={`${classes.title} font-80 `}>
                            Saperavi
                        </h1>
                        <h4 className={`${classes.subtitle} font-16-poller`}>
                            {t('home_banner_subtitle')}
                        </h4>
                        <div className={`${classes.text} ${classes.textDesc} font-16`}>
                            <p>
                                {t('home_banner_text_01')}
                            </p>
                            <p>
                                {t('home_banner_text_02')}
                            </p>
                        </div>
                        <div className={classes.btn}>
                            <Button primary>
                                {t('btn_reservation')}
                            </Button>
                        </div>
                    </div>
                    <div className={classes.images}>
                        <div ref={fryerRef} className={classes.fryer}>
                            <img src={fryer} alt=''/>
                        </div>
                        <div className={classes.items}>
                            {items.map((item, index) =>
                                <img 
                                    ref={item.ref}
                                    src={item.image}
                                    className={`${classes.item} ${item.class}`} 
                                    key={index} 
                                    alt=''
                                />
                            )}
                        </div>
                    </div>
                    <img className={classes.imageMob} src={image_mob} alt=''/>
                    <div className={`${classes.text} ${classes.textMob} font-16`}>
                        <p>
                            {t('home_banner_text_01')}
                        </p>
                        <p>
                            {t('home_banner_text_02')}
                        </p>
                    </div>
                </div>
            </div>
            <img ref={smokeRef_01} className={classes.smoke_01} src={smoke_01} alt=''/>
            <img ref={smokeRef_02} className={classes.smoke_02} src={smoke_02} alt=''/>
        </section>
    );
};

export default Banner;
