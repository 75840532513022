import _ from 'lodash';
import React from "react";
import classes from './CartItem.module.scss';
import ItemActions from '../../ItemActions/ItemActions';
import { useCartStore } from "../../../../store/cartStore";
import { useEtcStore } from '../../../../store/etcStore';

const host = process.env.REACT_APP_MEDIA_HOST;

const CartItem = ({ item }) => {
    const { language, currency } = useEtcStore();
    const { cart } = useCartStore();

    const getPrice = (id) => {
        const cartItem = _.find(cart, { id });
        return cartItem.price * cartItem.count;
    };
    
    return (
        <div className={`${classes.item} p15`}>
            <div className={`${classes.content} grid_1fr_120`}>
                <div className={classes.info}>
                    <span className={classes.count}>
                        {item.count} x
                    </span>&nbsp;
                    <span className={classes.title}>
                        {item.title[language]}
                    </span>
                </div>
                <div className={classes.media}>
                    <img src={`${host}/${item.media}`} alt={item.title[language]} />
                </div>
            </div>
            <div className={`${classes.actions} grid_1fr_120`}>
                <div className={classes.price}>
                    {getPrice(item.id)} {currency}
                </div>
                <ItemActions item={item} />
            </div>
        </div>
    );
};

export default CartItem;
