import React from "react";
import classes from "./Button.module.scss";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import { useEtcStore } from "../../../store/etcStore";

const Button = ({ children, event, route, primary, secondary }) => {
  // const lang = Cookies.get('i18next');
  const { language } = useEtcStore();

  if (primary) {
    return (
      <a 
        className={`${classes.button} ${classes.primary}`}
        href="/menu.pdf"
        target="_blank"
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        {children}
      </a>
    );
  }
  
  if (route) {
    return (
      <a 
        href={`/${route}`}
        target="_blank"
        className={`${classes.button} ${classes.default}`}
      >
        {children}
      </a>
    );
  }

  return (
    <div 
      className={`${classes.button} ${classes.default} ${secondary && classes.secondary}`}
      onClick={event}
    >
      {children}
    </div>
  )
};

export default Button;
