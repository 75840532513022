import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const useMenuStore = create(
    persist((set) => ({
        categories: [],
        selectedCategory: {},
        subCategories: [],
        selectedSubCategories: [],
        menu: [],
        
        setCategories: (categories) => set({ categories }),
        setSelectedCategory: (selectedCategory) => set({ selectedCategory }),
        setSubCategories: (subCategories) => set({ subCategories }),
        setSelectedSubCategories: (selectedSubCategories) => set({ selectedSubCategories }),
        setMenu: (menu) => set({ menu }),
    }),
    {
        name: 'menu-storage',
        storage: createJSONStorage(() => sessionStorage),
    },
));
