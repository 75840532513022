import _ from 'lodash';
import React from "react";
import classes from './ItemActions.module.scss';
import { useCartStore } from '../../../store/cartStore';

const ItemActions = ({ item }) => {
    const { cart, addDish, removeDish } = useCartStore();
    return (
        <div className={`${classes.actions} no-select`}>
            <div 
                className={`
                    ${classes.action} 
                    ${classes.actionDec} 
                    ${_.find(cart, { id: item.id })?.count === 1 && classes.actionDel}
                `}
                onClick={() => removeDish(item)}
            ></div>
            <div className={classes.actionValue}>
                {_.find(cart, { id: item.id })?.count ?? 0}
            </div>
            <div 
                className={`${classes.action} ${classes.actionInc}`}
                onClick={() => addDish(item)}
            ></div>
        </div>
    );
};

export default ItemActions;
