import React, { useEffect, useRef } from 'react';
import classes from './Media.module.scss';
import { useTranslation } from 'react-i18next';
import image_01 from '../../../assets/img/HomePage/Media/first.jpg';
import image_02 from '../../../assets/img/HomePage/Media/second.jpg';
import image_03 from '../../../assets/img/HomePage/Media/third.jpg';
import image_04 from '../../../assets/img/HomePage/Media/forth.jpg';
import video_01 from '../../../assets/img/HomePage/Media/first.mp4';
import video_02 from '../../../assets/img/HomePage/Media/second.mp4';

const Media = () => {
  const { t } = useTranslation();

  return (
    <section className={classes.media}>
      <div className="container">
        <div class={classes.containerLoc}>
          <div class={classes.flexContainer}>
            <div className={classes.videoFirst}>
              <video autoPlay loop muted>
                <source src={video_01} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className={classes.rightBlock}>
              <div className={classes.imageFirst}>
                <img
                  src={image_01}
                  alt="Second Element Image"
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
              <div className={classes.imageSecond}>
                <img
                  src={image_02}
                  alt="Third Element Image"
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
            </div>
          </div>

          <div class={`${classes.flexContainer} ${classes.reverse}`}>
            <div className={classes.leftBlock}>
              <div class={classes.imageThird}>
                <img
                  src={image_03}
                  alt="Second Element Image"
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
              <div class={classes.imageForth}>
                <img
                  src={image_04}
                  alt="Second Element Image"
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
            </div>
            <div className={classes.videoFirst}>
              <video autoPlay loop muted>
                <source src={video_02} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Media;
