import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const useEtcStore = create(
    persist((set) => ({
        orderType: {},
        menuFilter: '',
        language: 'de',
        isMobile: Boolean,
        currency: '€',

        setOrderType: (orderType) => set({ orderType }),
        setMenuFilter: (menuFilter) => set({ menuFilter }),
        setLanguage: (language) => set({ language }),
        setIsMobile: (isMobile) => set({ isMobile }),
        setCurrency: (currency) => set({ currency }),
    }),
    {
        name: 'etc-storage',
        // storage: createJSONStorage(() => sessionStorage),
    },
));
