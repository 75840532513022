import _ from 'lodash';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const updateTotalPrice = (cart) => {
    let price = 0;
    _.forEach(cart, dish => price += (dish.price * dish.count));
    return price;
};

export const useCartStore = create(
    persist((set, get) => ({
        
        cart: [],
        totalPrice: 0,
        visible: false,

        addDish: (dish) => {
            let newCart = get().cart;
            const dishIndex = _.findIndex(newCart, { id: dish.id });

            dishIndex === -1
                // add new dish
                    ? newCart = [ ...newCart, { ...dish, count: 1 } ]
                // increase count of an existing dish
                    : newCart[dishIndex].count += 1;
            
            set(() => ({ 
                cart: newCart,
                totalPrice: updateTotalPrice(newCart),
            }));
        },
        
        removeDish: (dish, total = false) => {
            let newCart = get().cart;
            const dishIndex = _.findIndex(newCart, { id: dish.id });

            if (dishIndex === -1) {
                // dish doesn't exist in cart
                    return;
            }
            
            newCart[dishIndex].count <= 1 || total
                // remove dish
                    ? _.remove(newCart, { id: dish.id })
                // decrease count of an existing dish
                    : newCart[dishIndex].count -= 1;

            set(() => ({ 
                cart: newCart,
                totalPrice: updateTotalPrice(newCart),
            }));
        },

        clearCart: () => set({ cart: [], totalPrice: 0 }),
        
        setVisible: (visible) => set({ visible }),
    }),
    {
        name: 'cart-storage',
    },
));
