import React, { useEffect, useState } from "react";
import classes from './Slider.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/Slider/image_01.png';
import image_02 from '../../../assets/img/HomePage/Slider/image_02.png';
import image_03 from '../../../assets/img/HomePage/Slider/image_03.png';
import mob_image_01 from '../../../assets/img/HomePage/Slider/mob_image_01.png';
import mob_image_02 from '../../../assets/img/HomePage/Slider/mob_image_02.png';
import mob_image_03 from '../../../assets/img/HomePage/Slider/mob_image_03.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import 'swiper/css/pagination';

const cards = [
    {
        image: image_03,
        mob_img: mob_image_03,
        text: 'home_slider_01_text',
    },
    {
        image: image_02,
        mob_img: mob_image_02,
        text: 'home_slider_02_text',
    },
    {
        image: image_01,
        mob_img: mob_image_01,
        text: 'home_slider_03_text',
    },
];

const Slider = () => {
    const { t } = useTranslation();
    const [ swiper, setSwiper ] = useState({});
    const [ mob, setMob ] = useState(false);
    const handleScreenResize = (innerWidth) =>
        innerWidth <= 800 ? setMob(true) : setMob(false);
    useEffect(() => {
        handleScreenResize(window.innerWidth);
        window.addEventListener('resize', (e) => handleScreenResize(e.target.innerWidth));
    }, []);
    return (
        <section className="mt container">
            <div className={classes.swiperContainer}>
                <div className={classes.swiperWrap}>
                    <Swiper
                        modules={[ Navigation, Pagination ]}
                        direction={'horizontal'}
                        className="homeSwiper no-select"
                        spaceBetween={20}
                        slidesPerView={1}
                        grabCursor
                        pagination={{ clickable: true }}
                        onInit={(e) => { setSwiper(e) }}
                        breakpoints={{
                            801: {
                                direction: 'vertical',
                            },
                        }}
                    >
                        {cards.map((card, index) =>
                            <SwiperSlide key={index} className="homeSwiperSlide">
                                <div className={classes.card}>
                                    <img className={classes.cardImage} src={mob ? card.mob_img : card.image} alt=''/>
                                    <div className={classes.cardContent}>
                                        <div className={`${classes.title} font-40`}>
                                            {t('home_slider_01_title')}
                                        </div>
                                        <div className={`${classes.subtitle} font-16-poller`}>
                                            {t('home_slider_01_subtitle')}
                                        </div>
                                        <p className={`${classes.text} font-16`}>
                                            {t(card.text)}
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                    <div className={classes.navigation}>
                        <div 
                            onClick={() => swiper.slidePrev()} 
                            className={`${classes.nav} ${classes.navL}`}
                        ></div>
                        <div 
                            onClick={() => swiper.slideNext()} 
                            className={`${classes.nav} ${classes.navR}`}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Slider;
