import HomePage from '../pages/HomePage/HomePage';
import MenuPage from '../pages/MenuPage/MenuPage';
import MobMenuPage from '../pages/MobMenuPage/MobMenuPage';
import CheckoutPage from '../pages/CheckoutPage/CheckoutPage';

export const routes = [
    // { path: '/', element: HomePage },
    { path: '/:lang/', element: HomePage },
    // { path: '/:lang/menu', element: MenuPage },
    // { path: '/:lang/menu/list', element: MobMenuPage },
    // { path: '/:lang/checkout', element: CheckoutPage },
];

export const headerRoutes = [
    // { id: 0, path: 'menu', text: 'header_menu' },
    { id: 1, path: 'menu.pdf', text: 'header_menu' },
    { id: 2, path: 'bar.pdf', text: 'header_bar' },
];
