import _ from 'lodash';
import $ from 'jquery';
import React, { useEffect } from "react";
import classes from './Cart.module.scss';
import Button from '../Button/Button';
import CartItem from './CartItem/CartItem';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCartStore } from "../../../store/cartStore";
import { useEtcStore } from '../../../store/etcStore';

const Cart = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { currency } = useEtcStore();
    const { cart, totalPrice, visible, setVisible } = useCartStore();

    useEffect(() => {
        visible
            ? $('body').addClass("ovf-hidden ovf-padding")
            : $('body').removeClass("ovf-hidden ovf-padding");
    }, [ visible ]);

    useEffect(() => {
        if (cart.length === 0) {
            setVisible(false);
        }
    }, [ cart.length ]);

    useEffect(() => {
        setVisible(false);
    }, [ pathname ]);

    return cart.length !== 0 && (
        <div className={`${classes.cartWrap} ${visible && classes.visible}`}>
            <div onClick={() => setVisible(false)} className={classes.closeArea}></div>
            <div className={`${classes.container} container`}>
                <div className={classes.cart}>
                    <div className={classes.top}>
                        <div onClick={() => setVisible(false)} className={classes.close}></div>
                    </div>
                    <div className={classes.body}>
                        {cart.map((item) =>
                            <CartItem item={item} key={item.id} />
                        )}
                    </div>
                    <div className={`${classes.bottom} p15`}>
                        <Button secondary route="checkout">
                            {t('btn_pay')}
                        </Button>
                        <div className={classes.total}>
                            {t('cart.total')}: <span>{totalPrice} {currency}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cart;
