import icon_bookmark from '../assets/img/MenuPage/Sidebar/OrderType/bookmark.svg';
import icon_delivery from '../assets/img/MenuPage/Sidebar/OrderType/delivery.svg';
import icon_takeaway from '../assets/img/MenuPage/Sidebar/OrderType/takeaway.svg';

export const orderTypes = [
    {
        id: 0,
        title: 'order_type.bookmark',
        icon: icon_bookmark,
        name: 'Bookmark',
    },
    {
        id: 1,
        title: 'order_type.delivery',
        icon: icon_delivery,
        name: 'Delivery',
    },
    {
        id: 2,
        title: 'order_type.takeaway',
        icon: icon_takeaway,
        name: 'Takeaway',
    },
];